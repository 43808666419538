<!-- 操作日志 -->
<template>
  <div id="operation-log">
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>操作日志</span>
      </div>
      <div class="content">
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="requestParam">
            <el-form-item label="场站名称">
              <el-select filterable  :popper-append-to-body="false" v-model="requestParam.stationId" placeholder="请选择场站名称"
                clearable>
                <el-option v-for="item in stationData" :key="item.id" :label="item.stationName+' '+item.stationNumber"
                  :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="用户名称">
              <el-input placeholder="请输入用户名称" v-model.trim="requestParam.userName" clearable></el-input>
            </el-form-item>
            <el-form-item label="操作">
              <el-input placeholder="请输入操作" v-model.trim="requestParam.operationName" clearable></el-input>
            </el-form-item>
            <el-form-item label="操作对象">
              <el-input placeholder="请输入操作对象" v-model.trim="requestParam.operationType" clearable></el-input>
            </el-form-item>
            <el-form-item label="描述">
              <el-input placeholder="请输入描述" v-model.trim="requestParam.description" clearable></el-input>
            </el-form-item>
            <el-form-item label="开始时间">
              <el-date-picker type="date" placeholder="请选择开始时间" value-format="yyyy-MM-dd"
                v-model="requestParam.createdAtStart" :append-to-body="false" @change="checkDate"></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间">
              <el-date-picker type="date" placeholder="请选择结束时间" value-format="yyyy-MM-dd"
                v-model="requestParam.createdAtEnd" :append-to-body="false" :picker-options="pickerOptions"
                @change="checkDate"></el-date-picker>
            </el-form-item>
          </el-form>
          <div class="query-button">
            <el-button type="primary" icon="el-icon-search" @click="formQuery">查询</el-button>
          </div>
        </div>

        <el-table class="table" :data="tableData" v-loading="loading" element-loading-text="加载中"
          element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.3)">
          <div slot="empty">
            <div v-if="tableData.length == 0">
              <div class="empty-data-view">
                <div class="table-text">
                  {{ loading ? "" : "暂无数据" }}
                </div>
              </div>
            </div>
          </div>
          <el-table-column align="center" type="index" label="序号" width="75">
            <template slot-scope="scope">
              <span>{{
                (requestParam.pageNum - 1) * requestParam.pageSize +
                scope.$index +
                1
              }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="userName" label="用户名称">
          </el-table-column>
          <el-table-column align="center" prop="ip" label="IP地址">
          </el-table-column>
          <!-- <el-table-column align="center" prop="stationName" label="场站名称">
          </el-table-column>
          <el-table-column align="center" prop="stationNumber" label="场站编码">
          </el-table-column> -->
          <el-table-column align="center" prop="operationDict" label="操作">
          </el-table-column>
          <el-table-column align="center" prop="operationType" label="操作对象">
          </el-table-column>
          <el-table-column align="center" prop="description" label="描述" width="300">
          </el-table-column>
          <el-table-column align="center" prop="responseMessage" label="返回信息" width="200">
            <template slot-scope="scope">
              <template v-if="isOverflow(scope.row.responseMessage)">
                <el-tooltip :content="scope.row.responseMessage" placement="top" :popper-class="'tooltip'">
                  <div class="ellipsis">{{ scope.row.responseMessage }}</div>
                </el-tooltip>
              </template>
              <template v-else>
                <div class="ellipsis">{{ scope.row.responseMessage }}</div>
              </template>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="createTime" width="175" label="创建时间">
          </el-table-column>
          <el-table-column align="center" label="操作" prop="operate">
            <template slot-scope="scope">
              <div class="operate">
                <el-button type="text" @click="viewDialog(scope.row)">详情</el-button>
                <!-- <span>|</span>
                <el-button type="text" @click="deleteDialog(scope.row)">删除</el-button> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <button class="home-page" @click="homePage">首页</button>
          <el-pagination :current-page="requestParam.pageNum" :page-sizes="[10, 20, 30, 40]"
            layout="prev, pager, next, sizes,slot" prev-text="上一页" next-text="下一页" :total="total" background
            @size-change="changeSize" @current-change="changeCurrent">
          </el-pagination>
          <button class="tail-page" @click="tailPage">尾页</button>
          <div class="jump-pagination">
            <span class="text">跳至</span>
            <el-input size="mini" class="pagination-input" @input="checkPageNum" @change="inputValueChange"
              v-model.number="inputValue"></el-input>
            <span class="text">页</span>
            <button class="confirm" @click="confirm">确定</button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="详情" :visible.sync="viewVisible" width="751px">
      <el-form class="dialog-form" ref="form" :inline="true" :model="detailsData">
        <el-form-item label="用户名称">
          <el-input v-model="detailsData.userName" :readonly="true"></el-input>
        </el-form-item>
        <el-form-item label="IP地址">
          <el-input v-model="detailsData.ip" :readonly="true"></el-input>
        </el-form-item>
        <!-- <el-form-item label="场站名称">
          <el-input v-model="detailsData.stationName" :readonly="true"></el-input>
        </el-form-item> -->
        <el-form-item label="操作">
          <el-input v-model="detailsData.operationDict " :readonly="true"></el-input>
        </el-form-item>
        <el-form-item label="操作对象类型">
          <el-input v-model="detailsData.operationType" :readonly="true"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input type="textarea" :rows="3" v-model="detailsData.description" :readonly="true"></el-input>
        </el-form-item>
        <el-form-item label="返回信息">
          <el-input type="textarea" :rows="3" v-model="detailsData.responseMessage" :readonly="true"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">

      </div>
    </el-dialog>
    <el-dialog title="删除" :visible.sync="deleteVisible" width="436px">
      <div class="dialog-text">确定要删除该数据吗？</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteOperation">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { eventApi } from "@/api/event.js";
import { inspectionFormApi } from "@/api/inspectionForm.js";
export default {
  name: "",

  data() {
    return {
      tableData: [],
      requestParam: {
        pageNum: 1,
        pageSize: 10,
        createdAtEnd: null,
        createdAtStart: null,
        stationId: "",
        userName: "",
        operationName: "",
        operationType: "",
        description: "",
      },
      total: 0,
      inputValue: "",
      viewVisible: false,
      deleteVisible: false,
      stationData: [],
      detailsData: {},
      id: "",
      loading: false,
    };
  },

  created() {
    this.$store.commit("increment", "Eios控制面板");
    this.$store.commit("selectChild", "操作日志");
    this.$store.commit("selectChildren", "");
    sessionStorage.setItem("componentStatus", 0);
    this.$store.commit("updateStatus", 0)
  },
  mounted() {
    this.queryOperation();
    this.queryStationName()
  },

  methods: {
    isOverflow(content) {
      const lineHeight = 16;
      const numLines = Math.ceil(content.length / 42); // 每行大概显示42个字符
      // console.log("====>",numLines);
      return numLines >= 3;
    },
    viewDialog(item) {
      Object.assign(this.detailsData, item);
      this.viewVisible = true;
    },
    deleteDialog(item) {
      this.id = item.id;
      this.deleteVisible = true;
    },
    checkDate() {
      if (this.requestParam.createdAtStart && this.requestParam.createdAtEnd) {
        const startTime = new Date(this.requestParam.createdAtStart).getTime();
        const endTime = new Date(this.requestParam.createdAtEnd).getTime();
        if (startTime > endTime) {
          this.requestParam.createdAtEnd = null;
        }
      }
    },
    formQuery() {
      this.requestParam.pageNum = 1;
      this.queryOperation();
    },
    checkPageNum(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.inputValue = value
    },
    //页数输入
    inputValueChange() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      if (this.inputValue < 1) {
        this.inputValue = 1;
      } else {
        this.inputValue =
          this.inputValue < lastPage ? this.inputValue : lastPage;
      }
    },
    //首页
    homePage() {
      this.requestParam.pageNum = 1;
      this.queryOperation();
    },
    //尾页
    tailPage() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      this.requestParam.pageNum = lastPage;
      this.queryOperation();
    },
    confirm() {
      this.requestParam.pageNum = this.inputValue;
      this.queryOperation();
      this.inputValue = "";
    },
    changeSize(pageSize) {
      this.requestParam.pageSize = pageSize;
      this.queryOperation();
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.queryOperation();
    },
    queryOperation() {
      this.loading = true;
      eventApi.queryOperation(this.requestParam).then((res) => {
        this.loading = false;
        if (res.code === 200) {
          this.tableData = res.data;
          this.total = res.total;
        }
      });
    },
    queryStationName() {
      inspectionFormApi.queryStationName().then((res) => {
        if (res.code === 200) {
          this.stationData = res.data;
        }
      });
    },
    deleteOperation() {
      eventApi.deleteOperation(this.id).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.deleteVisible = false;
          this.queryOperation();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
  },
  computed: {
    pickerOptions() {
      const _this = this;
      return {
        disabledDate(time) {
          const limitDate = new Date(_this.requestParam.createdAtStart);
          limitDate.setHours(0, 0, 0, 0); // 重置时间为当天的开始时间

          return time.getTime() < limitDate.getTime();
        },
      };
    },
  },
  watch: {
    // 如果只需要监听对象中的一个属性值，则可以做以下优化：使用字符串的形式监听对象属性：
    "requestParam.pageNum": {
      // 执行方法
      handler(newValue, oldValue) {
        this.inputValue = newValue;
      },
      deep: true, // 深度监听
      immediate: true, // 第一次改变就执行
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
#operation-log {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
}

.main {
  width: 100%;
  padding: 20px 26px 28px 25px;
  box-sizing: border-box;
}

/* 弹窗 */
.dialog-form {
  width: 688px;
  margin: 41px 32px 0 31px;
}

.dialog-form :deep().el-form-item__label {
  width: 100px;
  color: #c9d4f1;
  font-size: 12px;
  text-align: right;
}

.dialog-form :deep() .el-input .el-input__inner,
.dialog-form :deep() .el-textarea textarea {
  width: 539px;
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: rgba(255, 255, 255, 0);
}
</style>
<style>
.tooltip {
  max-width: 600px;
  /* 设置最大宽度 */
  line-height: 20px;
  max-height: 600px;
  overflow: auto;
}
</style>
